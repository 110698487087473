var site = site || {};
var Mustache = Mustache || {};

(function($) {
  site.cartConfirm = function() {
    var that = {
      content : null,
      kitContent: null,
      init : function() {
        this.content = $('script.inline-template[path="cart_confirm"]').html();
        this.kitContent = $('script.inline-template[path="cart_kit_confirm"]').html();
        if (typeof this.content == 'undefined' || !this.content) { return null; }
        $(document).on('addToCart.success', function(e, cartResult) {
          if (typeof (cartResult.getItem) != 'function') { return null; }
          //update cart count
          var item_count = cartResult.getCount();
          $('.page-utilities__cart-count').html(item_count);
          //don't show cart dropdown if flag is set
          if (typeof (cartResult.updateCountOnly) != 'undefined' ) { return null; }
          
          //Muliple Hex
          var sku = cartResult.getItem().product.sku
          var shadeHex = cartResult.getItem().product.sku.HEX_VALUE_STRING;
          if (shadeHex != null) {
              // explode
              var shadeHex = shadeHex.split(',');
              if (shadeHex.length == 1) {
                  sku['SWATCH_TYPE'] = 'single';
                  sku['HEX_VALUE_1'] = shadeHex[0];
              } else if (shadeHex.length == 2) {
                  sku['SWATCH_TYPE'] = 'duo';
                  sku['HEX_VALUE_1'] = shadeHex[0];
                  sku['HEX_VALUE_2'] = shadeHex[1];
              } else if (shadeHex.length == 3) {
                  sku['SWATCH_TYPE'] = 'trio';
                  sku['HEX_VALUE_1'] = shadeHex[0];
                  sku['HEX_VALUE_2'] = shadeHex[1];
                  sku['HEX_VALUE_3'] = shadeHex[2];
              } else if (shadeHex.length == 5) {
                  sku['SWATCH_TYPE'] = 'quint';
                  sku['HEX_VALUE_1'] = shadeHex[0];
                  sku['HEX_VALUE_2'] = shadeHex[1];
                  sku['HEX_VALUE_3'] = shadeHex[2];
                  sku['HEX_VALUE_4'] = shadeHex[3];
                  sku['HEX_VALUE_5'] = shadeHex[4];
              }
          }
          var skuBaseID = cartResult.getItem().product.sku.SKU_BASE_ID;
          if (skuBaseID != null) {
          var allItems = cartResult.getAllItems();
            var preOrderCartItems;
            if (typeof cartResult.getTransData !== 'undefined') {
              preOrderCartItems = typeof cartResult.getTransData().preorder !== 'undefined' ? cartResult.getTransData().preorder.items : '';
              allItems = allItems.concat(preOrderCartItems);
            }
          $.each(allItems, function(i, item) {
              if (item && item['sku.SKU_BASE_ID'] === skuBaseID) {
                cartResult.getItem().preOrderMsgShort = item.preOrderMsgShort;
                return false;
              }
          });
          }
          if (skuBaseID != null) {
            that.launch(cartResult.getItem());
          }
        });
        var _normalizeResponseKeys = function(items, recursionLevel) {
          recursionLevel = recursionLevel || 0;
          if (Object.prototype.toString.call(items) !== '[object Array]' || recursionLevel > 1) {
            return items;
          }
          var replaceKey = function(key) {
            return key.replace(/\./, '_');
          };
          var out = [];
          for (var i = 0, len = items.length; i < len; i++) {
            out[i] = {};
            for (var key in items[i]) {
              if (items[i].hasOwnProperty(key)) {
                out[i][replaceKey(key)] = items[i][key];
              }
              if (key === 'items' && items[i][key] && items[i][key].length) {
                out[i][replaceKey(key)] = _normalizeResponseKeys(items[i][key], recursionLevel + 1);
              }
            }
          }
          return out;
        };
        $(document).on('addKitToCart.success', function(e, cartResult) {
          var collectionId = cartResult.coll_info.COLLECTION_ID;
          var kitItem = {
            itemInfo: {},
          };
          var kit = cartResult.trans_data.order.kits;
          var addedItem = _.filter(kit, function(item) {
            if (item['COLLECTION_ID'] === collectionId) {
              return item;
            }
          });
          kitItem.itemInfo = _normalizeResponseKeys(addedItem);
          var itemCount = cartResult.trans_data.items_count;
          $('.page-utilities__cart-count').html(itemCount);
          that.launch(kitItem);
        });
      },
      launch : function(cartItem) {
        var html = '';
        if (cartItem.hasOwnProperty('itemInfo')) {
          html = Mustache.render(this.kitContent, cartItem);
        } else {
          html = Mustache.render(this.content, cartItem);
          if (parseInt(cartItem.product.sku.PRICE, 10) === 0) {
            $('.js-cart-confirm-engrave-price').html(cartItem.product.sku.formattedPrice);
          }
        }
        if (html !== '') {
          $('.cart-confirm__content').html(html).parent().fadeIn('200');
        }
        /**
         * Override engraving price defined in the CMS.
         * For priced skus, it is $10 defined in the CMS
         * For promotional skus, it is $0.
         */
        $(document).trigger('productQV:rendered:LoyaltyPrices', $('.cart-confirm__content'));
        setTimeout(function() {
          $('.cart-confirm__content').parent().fadeOut('200');
        }, 3500);
        if (site && site.track) {
          site.track.cartOverlay();
        }
      }
    };
    return that;
  }();
})(jQuery);

(function($) {
  site.wishlistConfirm = function() {
    var that = {
      content : null,
      init : function() {
        this.content = $('script.inline-template[path="wishlist_confirm"]').html();
        if (typeof this.content == 'undefined' || !this.content) { return null; }
        $(document).on('addToWishlist.success', function(e, cartResult) {
          // var ci = cartResult.getItem();
          that.launch({wishlist_add_success: true});
        });
        $(document).on('addToWishlist.exists', function(e, result) {
          that.launch({wishlist_add_exists: true});
        });
      },
      launch : function(args) {
        var html = Mustache.render(this.content, args);
        $.colorbox({
          html: html,
          className: 'colorbox__wishlist-confirm',
          width: "310px",
          height: "240px"
        });
        $(".js-wishlist-confirm-close").one( "click", function(){
          $.colorbox.close();
        });
      }
    };
    return that;
  }();
})(jQuery);
